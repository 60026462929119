import Button from '@/components/button';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { FunctionComponent } from 'react';

const ViewTourModal: FunctionComponent<{
	onCloseClick: () => void;
	tourId: string;
}> = ({ onCloseClick, tourId }) => {
	const { push } = useRouter();
	// const [selectedTourType, setSelectedTourType] = useState<'normal' | 'guided' | undefined>(undefined);
	// const [isLoading, setIsLoading] = useBoolean(false);

	const getContent = () => {
		// if (selectedTourType === 'normal') {
		// 	return (
		// 		<Fragment>
		// 			{isLoading && (
		// 				<div role='status' className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
		// 					<svg
		// 						aria-hidden='true'
		// 						className='mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600'
		// 						viewBox='0 0 100 101'
		// 						fill='none'
		// 						xmlns='http://www.w3.org/2000/svg'
		// 					>
		// 						<path
		// 							d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
		// 							fill='currentColor'
		// 						/>
		// 						<path
		// 							d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
		// 							fill='currentFill'
		// 						/>
		// 					</svg>
		// 					<span className='sr-only'>Loading...</span>
		// 				</div>
		// 			)}
		// 			<iframe
		// 				onLoad={() => setIsLoading(false)}
		// 				allowFullScreen
		// 				src={`/tour/${tourId}`}
		// 				className='m-0 h-full w-full grow rounded-lg border-0 p-0'
		// 			/>
		// 		</Fragment>
		// 	);
		// } else if (selectedTourType === 'guided') {
		// 	return (
		// 		<Fragment>
		// 			{isLoading && (
		// 				<div role='status' className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
		// 					<svg
		// 						aria-hidden='true'
		// 						className='mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600'
		// 						viewBox='0 0 100 101'
		// 						fill='none'
		// 						xmlns='http://www.w3.org/2000/svg'
		// 					>
		// 						<path
		// 							d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
		// 							fill='currentColor'
		// 						/>
		// 						<path
		// 							d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
		// 							fill='currentFill'
		// 						/>
		// 					</svg>
		// 					<span className='sr-only'>Loading...</span>
		// 				</div>
		// 			)}
		// 			<iframe
		// 				onLoad={() => setIsLoading(false)}
		// 				allowFullScreen
		// 				src={`/guided-tour/${tourId}`}
		// 				className='m-0 h-full w-full grow rounded-lg border-0 p-0'
		// 			/>
		// 		</Fragment>
		// 	);
		// } else {
		return (
			<div className='flex h-full w-full flex-col gap-4 lg:flex-row'>
				<div className='flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-[12px] border border-white bg-[#14142B] pb-5 text-center lg:w-1/3'>
					<div className='aspect-video w-full p-2 lg:p-5'>
						<div className='relative mb-5 aspect-video w-full'>
							<Image fill src='/images/immersive-tour.png' alt='normal-tour'></Image>
						</div>
					</div>
					<div className='px-2 lg:px-5'>
						<p className='text-lg font-semibold text-white'>Dive Deep With Our 3D VR Tour*</p>
						<p className='text-base font-normal text-white'>
							Elevate your property browsing experience with our cutting-edge 3D VR Tour. With efizbo,
							house hunting has been redefined:
						</p>
						<p className='text-xs font-light text-white'>* VR device recommended</p>
						<div className='mt-4'>
							<Button
								variant='blue'
								onClick={() => {
									push(`/immersive-tour/${tourId}`);
								}}
							>
								Start 3D VR Tour
							</Button>
						</div>
					</div>
				</div>
				<div className='flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-[12px] border border-white bg-[#14142B] pb-5 text-center lg:w-1/3'>
					<div className='aspect-video w-full p-2 lg:p-5'>
						<div className='relative mb-5 aspect-video w-full'>
							<Image fill src='/images/normal-tour.png' alt='normal-tour'></Image>
						</div>
					</div>
					<div className='px-2 lg:px-5'>
						<p className='text-lg font-semibold text-white'>Embark On A 360° Walkthrough*</p>
						<p className='text-base font-normal text-white'>
							If you&apos;re looking for a quick yet immersive view, our 360° walkthrough is your go-to.
							This isn&apos;t just any online listing:
						</p>
						<p className='text-xs font-light text-white'>* VR device recommended</p>
						<div className='mt-4'>
							<Button
								variant='blue'
								onClick={() => {
									push(`/tour/${tourId}`);
								}}
							>
								Start 360° Walkthrough
							</Button>
						</div>
					</div>
				</div>
				<div className='flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-[12px] border border-white bg-[#14142B] pb-5 text-center lg:w-1/3'>
					<div className='aspect-video w-full p-2 lg:p-5'>
						<div className='relative mb-5 aspect-video w-full'>
							<Image fill src='/images/guided-tour.png' alt='normal-tour'></Image>
						</div>
					</div>
					<div className='px-2 lg:px-5'>
						<p className='text-lg font-semibold text-white'>Take Our Guided 360° Walkthrough*</p>
						<p className='text-base font-normal text-white'>
							Enjoy touring the home with the help of the efizbo Virtual Property Specialist.
						</p>
						<p className='text-xs font-light text-white'>* VR device recommended</p>
						<div className='mt-4'>
							<Button
								variant='blue'
								onClick={() => {
									push(`/guided-tour/${tourId}`);
								}}
							>
								Start Guided 360° Walkthrough
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
		// }
	};

	return (
		<div className='relative z-50' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
			<div className='fixed inset-0 bg-[#14142B99] backdrop-blur-md transition-opacity'></div>
			<div className='fixed inset-0 z-10 overflow-y-auto'>
				<div className='flex min-h-screen items-center justify-center p-4 text-center sm:p-0'>
					<div className='relative mx-5 w-full max-w-[90vw] rounded-lg border border-[#FFFFFF66] bg-[#262338] p-[24px] text-left shadow-xl transition-all'>
						<div className='absolute -right-6 -top-3'>
							<Button size='small' onClick={onCloseClick}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z'
										fill='currentColor'
									/>
								</svg>
							</Button>
						</div>
						<div className='h-full min-h-full'>{getContent()}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewTourModal;
